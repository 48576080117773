import React from 'react'

const Footer: React.FunctionComponent = () => (
  <footer>
    <p>Designed with &lt;3 by RSTLSS &copy; </p>
    <p>{new Date().getFullYear()} Mark Sluimers Treasury Consultancy</p>
  </footer>
)

export default Footer
